import React from 'react';

import { SnackbarProvider } from 'notistack';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ApolloProvider } from 'react-apollo';

import './App.css';

import apiClient from './utils/graphqlClient';

import GameImages from './components/GameImages';
import GamesMenu from './components/GamesMenu';

import { getList as getGamesList } from './utils/gameAccess';

function App() {
  const gamesList = getGamesList();

  const [game, setGame] = React.useState(gamesList[0]);
  const [gameMenuOpen, setGameMenuOpen] = React.useState(false);

  const handleGameMenuClick = () => {
    setGameMenuOpen(true);
  };

  const handleGameMenuClose = () => {
    setGameMenuOpen(false);
  };

  const handleGameMenuSelected = selectedGame => {
    setGame(selectedGame);
    setGameMenuOpen(false);
  };

  return (
    <ApolloProvider client={apiClient}>
      <div className="App">
        <SnackbarProvider maxSnack={3}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleGameMenuClick}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6">{game}</Typography>
            </Toolbar>
          </AppBar>
          <GamesMenu
            list={gamesList}
            selected={game}
            open={gameMenuOpen}
            onClose={handleGameMenuClose}
            onSelect={handleGameMenuSelected}
          />
          <Container>
            <GameImages gameId={game} />
          </Container>
        </SnackbarProvider>
      </div>
    </ApolloProvider>
  );
}

export default App;
