import gql from 'graphql-tag';

const gqlFetchGameImages = gql`
  query fetchImages($gameId: ID!) {
    images(gameId: $gameId) {
      name
      path
      url
    }
  }
`;

export { gqlFetchGameImages as default };
