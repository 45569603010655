import gql from 'graphql-tag';

const gqlAddGameImage = gql`
  mutation addImages($gameId: ID!, $file: Upload!) {
    addImage(gameId: $gameId, file: $file) {
      name
      path
      url
    }
  }
`;

const gqlDeleteGameImage = gql`
  mutation deleteImages($gameId: ID!, $path: String!) {
    deleteImage(gameId: $gameId, path: $path)
  }
`;

export { gqlAddGameImage, gqlDeleteGameImage };
