import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { getToken } from '../auth';

const API_URL = process.env.REACT_APP_API_URL;

const authLink = setContext((_, { headers, ...context }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...context,
  };
});

const uploadLink = createUploadLink({ uri: API_URL });

const cache = new InMemoryCache();

const apiClient = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache,
});

export { apiClient as default };
