import React from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';

import { useSnackbar } from 'notistack';

import { Grid } from '@material-ui/core';
import Image from './Image';
import NewImage from './NewImage';

import apiClient from '../utils/graphqlClient';
import gqlFetchGameImages from '../graphql/queries';
import { gqlAddGameImage, gqlDeleteGameImage } from '../graphql/mutations';

export default function GameImages({ gameId }) {
  const [uploadImage] = useMutation(gqlAddGameImage);
  const [deleteImage] = useMutation(gqlDeleteGameImage);

  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, data } = useQuery(gqlFetchGameImages, {
    client: apiClient,
    variables: { gameId },
  });

  const handleAdd = ({ file, done }) => {
    uploadImage({
      variables: {
        gameId,
        file,
      },
    }).then(() => {
      apiClient.resetStore();
      enqueueSnackbar('Image has been added', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      done(true);
    });
  };

  const handleDelete = ({ path, done }) => {
    deleteImage({
      variables: {
        gameId,
        path,
      },
    }).then(() => {
      apiClient.resetStore();
      enqueueSnackbar('Image has been deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      done(true);
    });
  };

  if (error) {
    return <div>Error on load categories</div>;
  }
  if (loading) {
    return <div>Loading ...</div>;
  }
  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={1}>
        <Grid item xs={4} key={'new-image'}>
          <NewImage onAdd={handleAdd} />
        </Grid>
        {data.images.map(item => (
          <Grid item xs={4} key={item.name}>
            <Image key={item.name} data={item} onDelete={handleDelete} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
