import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';

import App from './App';
import NoAccess from './components/NoAccess';
import { initAuth, getInfo } from './auth';
import { init as gameAccessInit } from './utils/gameAccess';

initAuth()
  .success(auth => {
    if (auth) {
      if (!getInfo().hasRealmRole('games-images')) {
        render(NoAccess);
        return;
      }

      gameAccessInit({ gameIds: getInfo().idTokenParsed.gameIds });

      render(App);
    }
  })
  .error(() => {
    // eslint-disable-next-line no-console
    console.error('Authenticated Failed');
  });

function render(Component) {
  ReactDOM.render(<Component />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
