import React from 'react';
import {
  Drawer,
  Divider,
  IconButton,
  MenuList,
  MenuItem,
} from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default function GameMenu({ open, list, selected, onClose, onSelect }) {
  const handleClose = () => {
    onClose();
  };

  const handleSelect = ({ target }) => {
    const { id: selectedGame } = target;
    if (selectedGame === selected) {
      onClose();
    }
    onSelect(selectedGame);
  };

  return (
    <Drawer variant="persistent" anchor="left" open={open}>
      <IconButton onClick={handleClose}>
        <ChevronLeftIcon />
      </IconButton>
      <Divider />
      <MenuList>
        {list.map(game => (
          <MenuItem key={game} id={game} onClick={handleSelect}>
            {game}
          </MenuItem>
        ))}
      </MenuList>
    </Drawer>
  );
}
