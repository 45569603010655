import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Card,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

export default function Image({ data, onDelete }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete({ path: data.path, done: handleCloseMenu });
  };

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        }
        title={data.name}
      />
      <CardMedia className={classes.media} image={data.url} title={data.name} />
      <Menu
        id={`image-menu-${data.name}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem>
          <CopyToClipboard text={data.url} onCopy={handleCloseMenu}>
            <span>Copy URL to clipboard</span>
          </CopyToClipboard>
        </MenuItem>
      </Menu>
    </Card>
  );
}
