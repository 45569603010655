let gamesList = [];

function init({ gameIds }) {
  if (gameIds === undefined || gameIds.length === 0) {
    return;
  }
  const _gamesList = gameIds.reduce((final, games) => {
    games.match(/[^,]+/g).forEach(game => {
      const _game = game.trim();
      if (_game) final.add(_game);
    });
    return final;
  }, new Set());
  gamesList = Array.from(_gamesList);
}

function getList() {
  return gamesList;
}

export { init, getList };
