import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  content: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '30%',
    marginTop: '20px',
    marginBottom: '20px',
    minWidth: '350px',
  },
  img: {
    width: '100%',
  },
}));

const NoAccess = () => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <img
        className={classes.img}
        alt="No Access"
        src={`${process.env.PUBLIC_URL}/no_access.gif`}
      />
      <Typography variant="h5" component="h3">
        You Shouldn&apos;t be here
      </Typography>
      <Typography component="p">
        You don&apos;t have permission to view this App. keep moving
      </Typography>
    </div>
  );
};

export default NoAccess;
