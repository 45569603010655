import React from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Input,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function NewImage({ onAdd }) {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [isValid, setIsValid] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = ({
    target: {
      validity,
      files: [newFile],
    },
  }) => {
    setFile(newFile);
    setIsValid(validity.valid);
  };

  const handleAdd = () => {
    isValid && onAdd({ file, done: handleDone });
  };

  const handleDone = successfully => {
    setOpen(false);
  };

  return (
    <Card>
      <CardHeader title={'Add new image'} />
      <CardContent>
        <Box>
          <IconButton aria-label="add" onClick={handleClickOpen}>
            <AddCircleIcon fontSize={'large'} />
          </IconButton>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth={'sm'}
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Add new image</DialogTitle>
          <DialogContent>
            <Input
              required
              autoFocus
              margin="dense"
              id="file"
              label="Image file"
              type="file"
              fullWidth
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAdd} color="primary">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}
