import Keycloak from 'keycloak-js';

const {
  REACT_APP_KEY_CLOAK_REALM,
  REACT_APP_KEY_CLOAK_AUTH_SERVER_URL,
  REACT_APP_KEY_CLOAK_CLIENT_ID,
} = process.env;

const config = {
  realm: REACT_APP_KEY_CLOAK_REALM,
  url: REACT_APP_KEY_CLOAK_AUTH_SERVER_URL,
  clientId: REACT_APP_KEY_CLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(config);

function initAuth() {
  return keycloak.init({ onLoad: 'login-required' });
}

function getToken() {
  return keycloak.token;
}

function getInfo() {
  return keycloak;
}

export { initAuth, getToken, getInfo };
